import { NavLink as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, Divider } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import makeStyles from '@mui/styles/makeStyles';
import { useGetCaseBannerInfoQuery } from 'src/store/serverState';
import { Show } from 'src/components';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonRoot: {
    borderRadius: 0,
  },
  label: {
    width: 'initial',
  },
  listPadding: {
    width: 'initial',
  },
  buttonLeaf: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    color: theme.palette.primary.contrastText,
    justifyContent: 'start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightRegular,
  },
  topLevelButtonLeaf: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    justifyContent: 'start',
    textTransform: 'none',
    fontSize: '16px',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },

  active: {
    fontWeight: theme.typography.fontWeightMedium,
    background: theme.palette.primary.main,
    filter: 'brightness(.75)',
  },
}));

function NavItem({
  title,
  href,
  children,
  className,
  line: lineProp,
  disabledProp,
  topLevel,
  isOpen,
  ...rest
}) {
  const { caseId } = useParams();
  const banner = useGetCaseBannerInfoQuery(caseId, { skip: !caseId }).data;
  const displayWarning = banner?.hasReactiveSerology && title === 'Serologies';
  const classes = useStyles();

  return (
    <>
      {isOpen && <Divider />}
      <ListItem
        disableGutters
        className={classes.listItem}
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={topLevel ? classes.topLevelButtonLeaf : classes.buttonLeaf}
          classes={{
            label: classes.label,
            root: classes.buttonRoot,
          }}
          disabled={disabledProp}
          component={RouterLink}
          to={href}
        >
          <span className={classes.title}>{title}</span>
          <Show when={displayWarning}>
            <WarningAmberIcon color="warning" />
          </Show>
        </Button>
      </ListItem>
    </>
  );
}
NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  isOpen: PropTypes.bool,
  line: PropTypes.bool,
  disabledProp: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default NavItem;
